import { combineReducers } from "redux";

// Application Reducers
import app from "~/reducers/appReducer";
import auth from "~/reducers/authReducer";
import recoverLogin from "~/reducers/recover/recoverReducer";
import language from "~/reducers/languageReducer";
import system from "~/reducers/systemReducer";
import log from "~/reducers/logReducer";
import map from "~/reducers/mapReducer";
import mapToolbar from "~/reducers/mapToolbarReducer";
import mapFilterData from "~/reducers/mapFilterDataReducer";
import fleet from "~/reducers/fleetReducer";
import fleetVehicle from "~/reducers/fleetVehicleReducers";
import vehicleSearch from "~/reducers/vehicleSearchReducer";
import googleMapsData from "~/reducers/googleMapsDataReducer";
import reporting from "~/reducers/reportingReducer";
import info from "~/reducers/infoReducer";
import address from "~/reducers/addressReducer";
import exportDocument from "~/reducers/exportDocumentReducer";
import tabActive from "~/reducers/tabFilterReducer";
import reportTab from "~/reducers/reportTabReducer";
import driver from "~/reducers/driverReducer";
import travelsReport from "~/reducers/reporting/travelsReportReducer";
import eventReport from "~/reducers/reporting/eventReportReducer";
import alertReport from "~/reducers/reporting/alertReportReducer";
import speedingReport from "~/reducers/reporting/speedingReportReducer";
import distanceReport from "~/reducers/reporting/distanceReportReducer";
import groupDriversReducer from "~/reducers/groupDriversReducer";
import importDriver from "~/reducers/driver/importDriverReducer";
import dashboardToolbar from "~/reducers/dashboard/dashboardToolbarReducer";
import dashboardFilter from "~/reducers/dashboard/dashboardFilterReducer";
import dashboardCharts from "~/reducers/dashboard/dashboardChartsReducer";
import dashboardTravels from "~/reducers/dashboard/dashboardTravelsReducer";
import dashboardDataVehicle from "~/reducers/dashboard/dashboardDataVehicleReducer";
import dashboardTopTen from "~/reducers/dashboard/dashboardTopTenReducer";
import dashboardVehicleToolbar from "~/reducers/dashboardVehicle/dashboardToolbarReducer";
import dashboardChartsVehicle from "~/reducers/dashboardVehicle/dashboardChartsReducer";
import dashboardVehicleFilter from "~/reducers/dashboardVehicle/dashboardFilterReducer";
import dashboardVehicleTravels from "~/reducers/dashboardVehicle/dashboardTravelsReducer";
import dashboardVehicleCharts from "~/reducers/dashboardVehicle/dashboardChartsReducer";
import dashboardTravelsDay from "~/reducers/dashboardVehicle/dashboardTravelsDayReducer";
import dashboardVehicleTabTravels from "~/reducers/dashboardVehicle/dashboardTabTravelsReducer";
import pointsInterest from "~/reducers/pointsInterestReducer";
import groupPoints from "~/reducers/groupPointsReducer";
import communicate from "~/reducers/communicatedReducer";
import fleetsRegister from "~/reducers/fleets/fleetsRegisterReducer";
import user from "~/reducers/userReducer";
import alertsViolated from "~/reducers/alertsViolatedReducer";
import tutorialReducer from "~/reducers/tutorialReducer";
import profile from "~/reducers/profileReducer";
import alert from "~/reducers/alertReducer";
import vehicle from "~/reducers/vehicleReducer";
import route from "~/reducers/routeReducer";
import loginTab from "~/reducers/loginTabReducer";

export default combineReducers({
    app,
    auth,
    recoverLogin,
    language,
    system,
    log,
    map,
    reporting,
    fleetsRegister,
    vehicleSearch,
    travelsReport,
    speedingReport,
    mapToolbar,
    mapFilterData,
    fleet,
    fleetVehicle,
    communicate,
    alertReport,
    googleMapsData,
    info,
    address,
    distanceReport,
    exportDocument,
    tabActive,
    reportTab,
    driver,
    eventReport,
    importDriver,
    groupDriversReducer,
    dashboardDataVehicle,
    dashboardFilter,
    dashboardToolbar,
    dashboardCharts,
    dashboardTravels,
    dashboardChartsVehicle,
    dashboardTopTen,
    dashboardVehicleToolbar,
    dashboardVehicleFilter,
    dashboardVehicleTravels,
    dashboardVehicleCharts,
    dashboardTravelsDay,
    dashboardVehicleTabTravels,
    pointsInterest,
    groupPoints,
    user,
    alertsViolated,
    profile,
    tutorialReducer,
    alert,
    route,
    vehicle,
    loginTab,
});
